import type { IRecipient } from '@critical-dates'
export const useGetRecipients = (
  recipients: IRecipient[],
  maxUsers: number = 3,
) => {
  const recipientNames = recipients.slice(0, maxUsers).map((recipient) => {
    let name = recipient?.name && recipient.name.trim()
    if (!name || name.length === 0) {
      name = recipient?.email
    }
    return name
  })

  const additionalUsersCount = recipients.length - maxUsers
  const additionalUsersText =
    additionalUsersCount > 0
      ? ` + ${additionalUsersCount} ${additionalUsersCount === 1 ? 'user' : 'users'}`
      : ''

  const result =
    recipientNames.length > 0
      ? recipientNames.join(', ') + additionalUsersText
      : 'No recipients'

  return result
}
